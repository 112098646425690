import { PROVIDER } from '../constants';
import { AllOAuthGrantDetails, Auth, Grant } from '../store';
import { validateEmail } from './inputUtils';

// Amplify doesn't export this type properly
export type AmplifyAuthProvider = 'Amazon' | 'Apple' | 'Facebook' | 'Google';

// const GOOGLE_READ_ONLY_SCOPES = [
//   'https://www.googleapis.com/auth/calendar.readonly',
//   'https://www.googleapis.com/auth/calendar.events.readonly',
//   //'profile',
//   //'email'
// ];

// These are the permissions required if the user is able to access booking
// !THESE MUST BE IN SYNC WITH THE BACKEND SCOPES IN common.py
export const GOOGLE_BOOKING_SCOPES = [
  "https://www.googleapis.com/auth/calendar.readonly",
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "openid"
];


export const getGrants = (auth: Auth): Array<Grant> => {
  if (auth.user?.oauth_grant_details) {
    const { oauth_grant_details } = auth.user;
    return Object.keys(oauth_grant_details)
      .filter(provider => Object.keys(oauth_grant_details[provider]).length > 0)
      .map(provider => {
        const allDetails = oauth_grant_details[provider];
        // We don't currently support multiple oauth per provider, so we just look at the first one here
        const username = Object.keys(allDetails)[0];
        return {
          provider,
          username,
          account_user_id: allDetails[username].account_user_id,
          user_id: allDetails[username].user_id,
          scope: allDetails[username].scope,
          token_id: allDetails[username].token_id,
          signedIn: true,
        };
      });
  } else {
    return [];
  }
}; 

export const getGoogleBookingScope = (): string => {
  // This gives new scopes to anyone with the booking feature flag on
  const scopes = GOOGLE_BOOKING_SCOPES;
  return scopes.join(' ');
};


// TODO: We can probably make this a bit more efficient by making these selectors
export const checkForProviderGrant = (oauth_grant_details: AllOAuthGrantDetails, provider: string): boolean => (
  Object.keys(oauth_grant_details).some(p => 
    p === provider && Object.keys(oauth_grant_details[p]).length > 0
  )
);

export const checkForGoogleGrant = (oauth_grant_details: AllOAuthGrantDetails): boolean => (
  checkForProviderGrant(oauth_grant_details, PROVIDER.GOOGLE.name)
);

export const checkForMicrosoftGrant = (oauth_grant_details: AllOAuthGrantDetails): boolean => (
  checkForProviderGrant(oauth_grant_details, PROVIDER.MICROSOFT.name)
);


// Check if there is any google or microsoft grant in the available set
export const checkForCalendarGrants = (oauth_grant_details: AllOAuthGrantDetails): boolean => (
  checkForGoogleGrant(oauth_grant_details) || checkForMicrosoftGrant(oauth_grant_details)
);

export const getEmailDomain = (email: string) => {
  if (!validateEmail(email)) {
    throw new Error('invalid email');
  }
  
  return email.split("@")[1].toLowerCase();
};

export const useNewTier = () => {
  return import.meta.env.VITE_DEPLOY_ENV !== "dev";
};
